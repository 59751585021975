import React from 'react'
import { RichText } from 'prismic-reactjs'
import { Link, graphql, StaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import { withPreview } from 'gatsby-source-prismic-graphql';

import s from "./index.module.scss"

import 'bootstrap/dist/css/bootstrap.css';
import '../../stylesheets/main.scss'

import CartContext from "../../context/CartConext"
import LogRocket from 'logrocket'

LogRocket.init('lba-photographie/magasin')

const Layout = ({ data, children }) => {
  const categories = data.prismic.allCategoriess.edges.map(e => e.node);
  let layout = data.prismic.allLayouts.edges[0];

  console.log("LAYOUT & CATS", layout, categories)

  if (!categories || !layout) return null

  layout = layout.node

  // Call to render the classic edit button
  if (process.browser) window.prismic.setupEditButton();

  return (<React.Fragment>
    <Helmet>
       <meta charSet="utf-8" />
       <title>LBA Photography Store</title>
       <link href="https://use.fontawesome.com/releases/v5.1.0/css/all.css" rel="stylesheet" integrity="sha384-lKuwvrZot6UHsBSfcMvOkWwlCMgc0TaWr+30HWe3a4ltaBwTZhyTEggF5tJv8tbt" crossorigin="anonymous" />
       <link rel="stylesheet" href="https://use.typekit.net/glj6xss.css"/>
     </Helmet>
     <div className={s.banner}>
        Free shipping on all orders in Canada, USA and Europe!
     </div>
     <header className={s.siteHeader}>
        <nav className={s.stieNav}>
          {categories.map(cat => <Link className={s.navLink} to={`/${cat._meta.uid}`} key={cat._meta.uid}>{RichText.asText(cat.title)}</Link>)}
          <a className={`${s.navLink} text-muted`} href="https://lbaphotographie.ca">Gallery</a>
        </nav>
        <div className={s.logoContainer}>
          <Link to="/">
            <img className={s.logo} src="https://pro2-bar-s3-cdn-cf.myportfolio.com/da27c683cf400276c64d6a33ea832608/1dfe3edd-2342-4be6-8382-77fdc9ce8a81_rwc_0x0x882x1082x4096.png?h=5e4a2b4601cdde81cf2ec4a8a4a57500" alt="LBA Photography Logo" />
          </Link>
        </div>
        <nav className={s.siteSocial}>
            {layout.social_medias.map(social => 
              <a className={s.socialLink} href={social.link.url} target="_blank" rel="noopener noreferrer" key={social.link.url}>
                <img src={social.image.url} alt={`Social media icon: ${s.socialLink}`} />
              </a>
            )}
            <CartContext.Consumer>
              {({items}) => <Link className={s.socialLink} to="/cart">
                <div className={s.roundedPill}>
                  {RichText.asText(layout.checkout_label)} ({items.length})
                </div>
              </Link>}
            </CartContext.Consumer>
            
        </nav>
      </header>
     <div className={s.mainContentLayout} style={{ margin: '20px 3% 90px 3%' }}>
       {children}
     </div>
     <footer className={s.footer}>
       <div className="d-flex justify-content-between">
         <small>&copy; {new Date().getFullYear()}. Loïc Bellemare-Alford. All rights reserverd.</small>
          <div>
          {layout.footer_links.map((link, index) => <Link to={link.link.url} className="ml-2" key={index}>{RichText.asText(link.title)}</Link>)}
          </div>
       </div>
       
     </footer>
  </React.Fragment>)
}

const query = graphql`
{
  prismic{
    allLayouts {
      edges {
        node {
          checkout_label
          social_medias {
            title
            link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            image {
              ... on PRISMIC__ImageLink {
                url
              }
            }
          }
          footer_description
          footer_links {
            title
            link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
          }
        }
      }
    }
    allCategoriess(sortBy: menu_order_ASC) {
      edges {
        node {
          title
          _meta {
            uid
          }
        }
      }
    }
  }
}
`

export default (props) => (
  <StaticQuery
    query={query}
    render={withPreview(data => <Layout data={data} {...props} />, query)}
  />
);